import router from "@/router";
import { ref, onMounted } from "vue";

import { handleError } from "@/utils/errorHandler";
import OtpService from "@/services/otp_services";

export default {
  name: "VerifyOtp",
  components: {},
  setup(props, context) {
    const otpStatus = ref({
      sendingOtp: false,
      otpSent: false,
      verifyingOtp: false,
    });
    const data = ref({ otp: undefined });
    const showPassword = ref(false);

    onMounted(async () => {});

    const sendOtpToAdmin = () => {
      otpStatus.value.sendingOtp = true;
      OtpService.getOtp()
        .then(() => {
          otpStatus.value.sendingOtp = false;
          otpStatus.value.otpSent = true;
        })
        .catch((error) => {
          handleError(context, error);
          otpStatus.value.sendingOtp = false;
          otpStatus.value.otpSent = false;
        });
    };

    const verifyOtp = () => {
      otpStatus.value.sendingOtp = true;
      OtpService.verifyOtp(data.value)
        .then(() => {
          alert("Otp Verified");
          router.push({ name: "AdminVoucher" });
        })
        .catch((error) => {
          handleError(context, error);
          otpStatus.value.verifyingOtp = false;
        });
    };

    onMounted(() => {
      // console.log(localStorage);
    });

    const logout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      router.push({ name: "Home" });
    };

    return {
      otpStatus,
      data,
      showPassword,
      logout,
      sendOtpToAdmin,
      verifyOtp,
    };
  },
};
